import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { COMPANY_NAME, LANGUAGES } from '../constants';

// Separate component for navigation items
const NavigationItems = ({ handleClick, t }) => (
  <Nav className="me-auto">
    {['home', 'about', 'contact'].map((section) => (
      <Nav.Link 
        key={section} 
        href={`#${section}`} 
        onClick={(event) => handleClick(event, section)}
      >
        {t[section]}
      </Nav.Link>
    ))}
  </Nav>
);

// Separate component for theme and language toggles
const ToggleButtons = ({ darkMode, setDarkMode, language, setLanguage }) => (
  <div className="d-flex gap-2">
    <Button 
      variant="outline-secondary" 
      onClick={() => setDarkMode(!darkMode)}
      aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
    >
      <i className={`fas fa-${darkMode ? "sun" : "moon"}`}></i>
    </Button>
    <Button 
      variant="outline-secondary" 
      onClick={() => setLanguage(language === LANGUAGES.EN ? LANGUAGES.ID : LANGUAGES.EN)}
    >
      {language === LANGUAGES.EN ? "ID" : "EN"}
    </Button>
  </div>
);

function Header({ darkMode, setDarkMode, language, setLanguage, handleScroll, t }) {
  const handleClick = (event, section) => {
    event.preventDefault();
    handleScroll(section);
  };

  return (
    <Navbar 
      bg={darkMode ? 'dark' : 'light'} 
      variant={darkMode ? 'dark' : 'light'} 
      expand="lg" 
      fixed="top"
    >
      <Container>
        <Navbar.Brand href="#home">{COMPANY_NAME}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <NavigationItems handleClick={handleClick} t={t} />
          <ToggleButtons 
            darkMode={darkMode}
            setDarkMode={setDarkMode}
            language={language}
            setLanguage={setLanguage}
          />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;