import React from 'react';
import { COMPANY_NAME } from '../constants';

function Footer() {
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <footer>
      <p>&copy; {currentYear} {COMPANY_NAME}. All rights reserved.</p>
    </footer>
  );
}

export default Footer;